import { useState } from "react";
import { ChangeHandler, ClearData, isValidUrl } from "../../Elements/Functions"
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { PreventNegative } from "../../../../services/MathMatics";
import Select  from "react-select";
import { AxiosPostRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToasSucces, ToastError } from "../../../../services/Toasts";


export default function GeneralSetting (props) {
    const [Loader, setLoader] = useState(false);
    const [Langs, setLangs] = useState([
      {label:"Arabic", value:"ara"},
      {label:"Persian", value:"ara"},
      // {label:"English", value:"eng"},
    ]);

    const [Setting, setSetting] = useState(
        {
          Trend24Url:"",
          Language:0,
          operationId:null,
          socialAreas:[],
          privateRobotAccountId:null,
          genderType:"FemaleMale",
          isJustSee:false
        }
      );


      const settingsData = [
        { url: Urls.Settings.setTrendLink, data:  props.GeneralOptions.Trend24Url, name: 'URL' },
        { url: Urls.Settings.setGetDayTrendsLink, data: props.GeneralOptions.GetdaytrendsUrl, name: 'URL' },
        { url: Urls.Settings.setLang, data: props.GeneralOptions.Language, name: 'Language' }
      ];

    const updateSettings = async () => {

      console.log( settingsData);
          setLoader(true); // Start the loader
      try {
        // Send all requests concurrently
        const responses = await Promise.all(
          settingsData.map(setting => AxiosPostRequest(setting.url,[],{[setting.name]:setting.data}))
        );
        // Check if all requests succeeded
        const allSuccess = responses.every(response => response[0]);
        if (allSuccess) {
          ToasSucces("All settings have been added successfully");
        } else {
          // Handle individual errors
          responses.forEach((response, index) => {
            if (!response[0]) {
              ToastError(`Failed to add ${settingsData[index].name}`);
            }
          });
        }
      } catch (error) {
        // General error handling for network errors
        ToastError("An error occurred while saving settings.");
        console.error("Error:", error); // Log the error for debugging
      } finally {
        setLoader(false); // Stop the loader after all requests
      } 
    };
    
    
    
    return(
        <>
        <div className=" mobile-send-button">
            <Button onClick={()=>updateSettings()}>Send </Button>
        </div>
        <Card>
                  <Row className={"w-100 ml-0 pt-3 pb-4 mt-4 " }  style={{justifyContent: "space-between"}} >
                  <Col xl={3}>
                    <div className='form-group'>
                    <label><i className="fa fa-solid mr-1">T24</i> url</label>
                      <input
                        type='url'
                        className='form-control input-default p-2  text-black'
                        value={props.GeneralOptions.Trend24Url}
                        id={"Reports1"}
                        placeholder="https://trends24.in"
                        style={{height:"2.4rem"}}
                        onChange={(e)=>ChangeHandler('Trend24Url',e.target.value,props.setGeneralOptions,props.GeneralOptions)}
                        onKeyPress={(e)=>PreventNegative(e)}
                      />
                    </div>
                    </Col><Col xl={3}>
                    <div className='form-group'>
                    <label><i className="fa fa-solid mr-1">GDT</i> url</label>
                      <input
                        type='url'
                        className='form-control input-default p-2  text-black'
                        value={props.GeneralOptions.GetdaytrendsUrl}
                        id={"Reports1"}
                        placeholder="https://getdaytrends.com"
                        style={{height:"2.4rem"}}
                        onChange={(e)=>ChangeHandler('GetdaytrendsUrl',e.target.value,props.setGeneralOptions,props.GeneralOptions)}
                        onKeyPress={(e)=>PreventNegative(e)}
                      />
                    </div>
                    </Col>
                    <Col xl={3}>
                      <label htmlFor="SingleAccount" className='form-check-label'>
                       Robot Language:
                      </label>
                      <Select 
                      isClearable 
                      onChange={(e) => ChangeHandler('Language',e !=null ? e.value:null,props.setGeneralOptions,props.GeneralOptions)} 
                      options={Langs} 
                      value={Langs.find(option => option.value === props.GeneralOptions.Language.value)}
                      />
                    </Col>
                </Row>
                <Row className="w-100 ml-0 pt-3 pb-2"  style={{borderTop:"1px solid #dbdbdb"}} >
                </Row>
        
        </Card>
        <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=> updateSettings()}>Send </Button>
          <Button variant='outline-primary ' onClick={()=> ClearData(setSetting,)}>Clear </Button>
          {Loader ? <Spinner animation="border" variant="primary" /> : "" }
        </Row>
        </>
    )
}