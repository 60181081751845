import { useState ,createRef, useEffect, useRef} from "react";
import { Button, Card, Col, ListGroup, OverlayTrigger, Row, Spinner, Tooltip, Popover } from "react-bootstrap";
import { ToasSucces, ToastError } from "../../../../services/Toasts";
import { TransitionGroup,CSSTransition } from "react-transition-group";
import Select from "react-select";
import { AccountColumn, GenderColumn, MultiSelectELM, OperationColumn, RenderTooltip, SubjectColumn, TagListColumn, TweetTextbox } from "../../Elements/Elements";
import { ChangeHandler, GenderCheckbox, Getmultiselect, handleKeyPress, read_clipboard } from "../../Elements/Functions";
import { AxiosGetRequest, AxiosPostRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";




export default function RetweetsForm (props) {
  const [selectedOption, setSelectedOption] = useState(false);
  const [TrendTypes, SetTrendsTypes] = useState(false);
  const [AvailAccounts, setAvailAccounts] = useState(0)
  const [RepeatVal, setRepeatVal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [TrendVal, setTrendVal] = useState(false);
  const MaleCheckRef = useRef(null);
  const FemaleCheckRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);

  const [Options, setOptions] = useState(
    {
      targetLink:"",
      operationId:null,
      tagCollectionId:null,
      privateRobotAccountId:null,
      countRepetition:"",
      commentOrderType:"text",
      socialAreas: [],
      isNeedtextRepetition:false,
      Files:[""],
      // trendMethodAddType: "Top",
      genderType:"FemaleMale",
    }
  );
    const [TweetList, setTweetList] = useState([
        {
            Text:"",
            Media:null,
            id:0,
            nodeRef: createRef(null),
        },

    ]);
    
    const AddTweet = () =>{
        const newTweet = 
        { 
        Text:"",
        Media:null,
        id:TweetList.length,
        nodeRef: createRef(null),
        }
        setTweetList(TweetList => [...TweetList, newTweet]);
    }
    
    const RemoveTweet = (index) =>
    {       

        if(TweetList.length>1){
        const ActionItemsList = [...TweetList];
        ActionItemsList.splice(index, 1);
        setTweetList(ActionItemsList);
        }
        else
        ToastError("At least One Retweet is needed",10)
    }

    const ChangeTweet = (e,index) =>
    {
         if(e.target.value.length < 281){
          const List = [...TweetList];
          List[index].Text = e.target.value;
          setTweetList(List);
        }
        else
        return

        
    }



    const RepeatCheckBoxFunction = (type,value,SetData,Data) => {
      setRepeatVal(value)
      ChangeHandler(type,value,SetData,Data)
      if(!value)
      ChangeHandler('countRepetition',0,setOptions,Options)
      
    }
      
      const SendTweets = () => 
      {
        var TweetsLists = []
        for (let i = 0; i < TweetList.length; i++) 
          {
            var Tweeet = {}
            Tweeet.text = TweetList[i].Text;
            Object.assign(Tweeet, Options);
    
            TweetsLists.push(Tweeet)
          }
          setLoader(true)
          AxiosPostRequest(Urls.Retweets.add,TweetsLists).then(response=>{
            setLoader(false)
            if(response[0])
          {
            ToasSucces("Retweet(s) has been added successfully")
            props.setRefreshData(!props.RefreshData)

          }
          else
            ToastError("An Error Has occurred")
          })
      }

      
      const BulkAdd = () => {
        let Repeat = document.getElementById("bulkAddInput").value
        for (let index = 0; index < Repeat; index++) {
          AddTweet()
        }
        setShowPopover(false)
      };
      
      const handleRightClick = (event) => {
        event.preventDefault();
        setShowPopover(!showPopover)
      };

  useEffect(() => {        
        if(JSON.parse(sessionStorage.getItem("TrendTypes"))!==null)
        SetTrendsTypes(JSON.parse(sessionStorage.getItem("TrendTypes")))

        setLoader(true)
        AxiosGetRequest(Urls.Acounts.AvailAccounts,[{"HeadKey":"orderType","HeadValue":"retweet"}]).then(response => {
        setLoader(false)
        console.log('response[1].data :>> ', response[1]);
          if(response[0])
          {
            setAvailAccounts(response[1].data)
          }
        })

  }, [])
  const handleKeyDown = (event) => 
    {
    handleKeyPress(event, 'Enter', BulkAdd);
    };
    return (
        <>
        <Card>
            <ListGroup >
                <div className="d-flex flex-row justify-content-between">
                    <h4>Rewteets With Comment Form</h4>
                    <div className="d-flex" style={{gap:"0.5rem"}}>
                        <OverlayTrigger   show={showPopover}placement="bottom" delay={{ show: 500, hide: 100 }}overlay={
                          <Popover   placement="bottom" style={{borderColor:"#97e7ff",borderRadius:"1vh"}}>
                          <Popover.Content>
                            <input id="bulkAddInput" className="form-control input-default " type="number" onKeyPress={handleKeyDown} />
                          </Popover.Content>
                          <Popover.Title closeButton onClick={()=>BulkAdd()} style={{background:"#97e7ff"}}>
                            Add
                          </Popover.Title>
                        </Popover>
                        }>
                            <div onContextMenu={handleRightClick} onClick={()=>AddTweet()} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"#026e31"}}>
                                <i className="fa-solid fa-plus text-white" ></i>
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='input-group  mb-3 input-primary ' >
                      <div className='input-group-prepend'>
                        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(1,"Paste")}>
                          <span onClick={() =>read_clipboard("TargetLink",setOptions,Options)} className='input-group-text c-pointer'><i className="fa-thin fa-paste"></i></span>
                        </OverlayTrigger>
                      </div>
                      <input
                        type='text'
                        className='form-control input-default text-black'
                        placeholder="Link"
                        value={Options.TargetLink} 
                        onChange={(e) => ChangeHandler('TargetLink',e.target.value,setOptions,Options)}
                      />
                </div>
                <TransitionGroup>
                {TweetList.map((Tweet,index) =>(
                    <CSSTransition
                    key={index}
                    nodeRef={Tweet.nodeRef}
                    timeout={500}
                    classNames="item"
                  >
                    <ListGroup.Item style={{background:"#b5e0f117"}} className="rounded mb-2 mt-2 border border-primary"  ref={Tweet.nodeRef}>
                    <TweetTextbox TweetList={TweetList} setTweetList={setTweetList} Tweet={Tweet} Index={index} ChangeTweet={ChangeTweet} RemoveTweet={RemoveTweet}/>
                    </ListGroup.Item>
                   </CSSTransition> 
                    ))}
                </TransitionGroup>
            </ListGroup>    
                <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}} >
                  <OperationColumn Options={Options} setOptions={setOptions}/>
                    <SubjectColumn Options={Options} setOptions={setOptions} setAvailAccounts={setAvailAccounts} setLoader={setLoader} type={"retweet"}/>
                    <TagListColumn Options={Options} setOptions={setOptions}/>
                </Row>
                <Row className="w-100 ml-0 pt-3 pb-2 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                  <AccountColumn Options={Options} setOptions={setOptions} CustomClass={"pt-0"}/>
                  <GenderColumn customID={1}   xl={"3"} Options={Options} setOptions={setOptions} MaleRef={MaleCheckRef} FemaleRef={FemaleCheckRef} />
                  <Col  xl={5}>
                    <div className="d-flex flex-row mt-2">
                    <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-3'>
                          <input
                          type='checkbox'
                          className='custom-control-input'
                          id='Repeat'
                          onChange={(e) => RepeatCheckBoxFunction('isNeedtextRepetition',e.target.checked,setOptions,Options)}
                          />
                          <label
                          className='custom-control-label'
                          htmlFor='Repeat'
                          >
                          Repeat:
                          </label>
                      </div>
                      <div style={{zIndex:0}}  className='d-flex custom-control custom-checkbox mr-3 pl-0'>
                      <input className='form-control input-default w-50 ' onChange={(e) => ChangeHandler('countRepetition',e.target.value,setOptions,Options)} value={Options.countRepetition} placeholder='Amount' type="number" disabled={!RepeatVal}/>
                        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"If checked, tweets will be repeated with slighlty changed text")}>
                          <i className="d-flex justify-content-center align-items-center c-pointer ml-2 fa-regular fa-question-circle"></i>
                        </OverlayTrigger>
                      </div>
                    </div>
                    </Col>
                </Row>
                <Row className="w-100 ml-0 pt-3 pb-3 mt-2"  style={{borderTop:"1px solid #dbdbdb"}}>
                  <Col xl={7}>
                  <div className="d-flex flex-row mt-2">
                      <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-2'>
                          <input
                          type='checkbox'
                          className='custom-control-input'
                          id='Trending'
                          onChange={(e) => setTrendVal(e.target.checked)}
                          />
                          <label
                          className='custom-control-label'
                          htmlFor='Trending'
                          >
                          Use Trending Hashtangs
                          </label>
                      </div>
                        <Select isDisabled={!TrendVal}  defaultValue={selectedOption} placeholder="Position"onChange={(e)=>ChangeHandler('trendMethodAddType',e !=null ? e.value:null,setOptions,Options)} options={TrendTypes} />
                      <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-2'>
                          <input
                          type='checkbox'
                          className='custom-control-input'
                          id='hashtaqg'
                          disabled={!TrendVal}
                          />
                          <label
                          className='custom-control-label'
                          htmlFor='hashtaqg'
                          >
                          Use 1st hashtaqg
                          </label>
                      </div>
                    </div>
                  </Col>
                </Row>
        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=>SendTweets(Options)}>Send </Button>
          <Button variant='outline-primary '>Clear </Button>
          <h5 className="mt-3 mr-2"> Available Accounts : {AvailAccounts}</h5>
          {Loader ? <Spinner style={{marginTop:"1rem"}} animation="border" className='mb-3' variant="primary" /> : "" }
       </Row>
        </>
    )
}