import { useState ,createRef, useEffect, useRef} from "react";
import { Button, Card, Col, ListGroup, Modal, OverlayTrigger, Popover, Row, Spinner,  } from "react-bootstrap";
import { ToastError,ToasSucces } from "../../../../services/Toasts";
import { TransitionGroup,CSSTransition } from "react-transition-group";
import Select from "react-select";
import { AccountColumn, GenderColumn, ImageGallery, OperationColumn, RenderTooltip, SubjectColumn, TagListColumn, TweetWithMedia, HashtagSourceSelect, HashtagMinMaxInput } from "../../Elements/Elements";
import {useDropzone} from 'react-dropzone';
import { Urls } from "../../../../services/ServerandUrls";
import { AxiosGetRequest, AxiosPostRequest } from "../../../../services/Connections";
import { AddTweet, ChangeHandler, handleKeyPress } from "../../Elements/Functions";


export default function TweetForm (props) {
  const [selectedOption] = useState(null)
  const [TrendTypes, SetTrendsTypes] = useState([
    {
        "value": "Top",
        "label": "Top"
    },
    {
        "value": "Buttom",
        "label": "Buttom"
    },
    {
        "value": "Random",
        "label": "Random"
    }
])
  const [focused, setfocused] = useState(false)
  const [AvailAccounts, setAvailAccounts] = useState(0)
  const [RepeatVal, setRepeatVal] = useState(false)
  const [TrendVal, setTrendVal] = useState(false)
	const [show, setShow] = useState(false);
	const [BulkMediaAdd, setBulkMediaAdd] = useState(false);
  const [files, setFiles] = useState([]);
  const [Vids, setVids] = useState([]);
  const [selectedImages, setselectedImages] = useState([]);
  const [bulkSelectImage, setbulkSelectImage] = useState([]);
  const [selectedTweet, setselectedTweet] = useState(0);
  const [Loader, setLoader] = useState(false);
  const [ListType, setListType] = useState(false);
  const MaleCheckRef = useRef(null);
  const SelectRef = useRef(null);
  const FemaleCheckRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);

  const [Options, setOptions] = useState(
    {
      operationId:null,
      tagCollectionId:null,
      privateRobotAccountId:null,
      countRepetition:"",
      socialAreas: [],
      isWithTrendMethod:false,
      isNeedtextRepetition:false,
      trendMethodAddType: "Top",
      minCountTrendTweets:"",
      maxCountTrendTweets:"",
      genderType:"FemaleMale",
      TrendSuorce:null
    }
  );
  const [TweetList, setTweetList] = useState([
    {
        Text:"",
        Files:[],
        TempMedia:[],
        id:0,
        nodeRef: createRef(null),
    },

  ]);
  const {getRootProps, getInputProps} = useDropzone({
    multiple:true ,
    accept: {
      'image/jpg': [".jpg"],
      'video/mp4': [".mp4"]
    },
    onDrop: acceptedFiles => {
      setLoader(true);
      for (let i = 0; i < acceptedFiles.length; i++) {
        let formData = new FormData();
        formData.append('File', acceptedFiles[i]);
        AxiosPostRequest(Urls.Upload,formData).then(response => {
          setLoader(false);
          if(response[0])
          {
            ToasSucces(acceptedFiles[i].name + " uploaded")
            Object.assign(acceptedFiles[i], {Address: response[1].data[0].savedFilePath});
            if(acceptedFiles[i].type == "video/mp4")
              setVids(current => [...current, acceptedFiles[i]])
            else
              setFiles(current => [...current, acceptedFiles[i]])
          }
          else
            ToastError(acceptedFiles[i].name + " didnt upload")
        })
        
      }
    }
  });

	const handleClose = () =>{
    setListType(false)
    setShow(false)
  }

	const handleModal = (index,mediaList,bulkAction) => 
  {
    setselectedImages(mediaList) 
    setShow(true);
    setselectedTweet(index)
    setBulkMediaAdd(bulkAction)
  }

  const RemoveTweet = (index,item) =>
  {       
      if(TweetList.length>1){
      const ActionItemsList = [...TweetList];
      ActionItemsList.splice(index, 1);
      setTweetList(ActionItemsList);
      }
      else
      ToastError("At least One Tweet is needed",10)
  }

  const ChangeTweet = (e,index) =>
  {
        if(e.target.value.length < 281){
        const List = [...TweetList];
        List[index].Text = e.target.value;
        setTweetList(List);
      }
      else
      return
  }

  const TrendVals = (e) => {
        setTrendVal(e)
        ChangeHandler('isWithTrendMethod',e,setOptions,Options)
        if(e == false)
        {
          SelectRef.current.clearValue()
          document.getElementById("hashtaqg").checked = false;
          setOptions({
            ...Options,
            trendMethodAddType: "Top" ,
            minCountTrendTweets:"",
            maxCountTrendTweets:"",
            TrendSuorce:null
          });
        }
  }

  const AddMedia = () => {
    let TempList = []
    selectedImages.forEach(element => {
      TempList.push(element.Img.Address)
    });
    var newState = [...TweetList];

    if(BulkMediaAdd)
    {
      newState = newState.map(tweet => {
        return {...tweet, Files: TempList ,TempMedia:selectedImages };
      })
    }
    else
    {if (selectedTweet >= 0 && selectedTweet < newState.length) {
      newState[selectedTweet] = {
        ...newState[selectedTweet],
        Files: TempList,
        TempMedia: selectedImages
      };
    }}
  setTweetList(newState)
    setListType(false)
    setShow(false)
  }

  const RepeatCheckBoxFunction = (type,value,SetData,Data) => {
    setRepeatVal(value)
    ChangeHandler(type,value,SetData,Data)
    if(!value)
    ChangeHandler('countRepetition',0,setOptions,Options)
    
  }

  const SendTweets = () => 
  {

    var TweetsLists = []
    for (let i = 0; i < TweetList.length; i++) 
      {
        var Tweeet = {}
        Tweeet.text = TweetList[i].Text;
        Tweeet.files = TweetList[i].Files;
        if(TweetList[i].TempMedia.length > 0)
        {
          if(TweetList[i].TempMedia[0].Type == "1")
          Tweeet.robotAccountTweetType = "VideoTweet";
          else
          Tweeet.robotAccountTweetType = "ImageTweet";

        }
        else
        Tweeet.robotAccountTweetType = "Tweet";

        const TempOptions = { ...Options };

        if(TempOptions.isWithTrendMethod)
        {
          TempOptions.maxCountTrendTweets = (TempOptions.maxCountTrendTweets * 1000)
          TempOptions.minCountTrendTweets = (TempOptions.minCountTrendTweets * 1000)

        }
        Object.assign(Tweeet, TempOptions);

        TweetsLists.push(Tweeet)
      }
      setLoader(true);
      AxiosPostRequest(Urls.Tweet.add,TweetsLists).then(response=>{
        setLoader(false);
          if(response[0])
          {
            ToasSucces("Tweet(s) has been added successfully")
            props.setRefreshData(!props.RefreshData)

          }
          else
            ToastError("Tweet(s) didn't add")
      })
  }

  const ReadFile = (event) => {
    if(event.target.files[0].type=="text/plain")
    {
        var input = event.target;
        var reader = new FileReader();
        var List = [];
        reader.onload = function() {      
            var Users = reader.result.split('$')
            
            Users.map((User,Index) => 
                {
                  if(User.slice(0,2)=="\r\n")
                  {
                    User = User.replace(/\r?\n|\r/, '');
                  }
                  var Tweet = 
                  {
                    Text:User,
                    Files:[],
                    TempMedia:[],
                    id:Index,
                    nodeRef: createRef(null),
                  }
                    List.push(Tweet)
                })
                setTweetList(List)
        };
        reader.readAsText(input.files[0]);   
    }
    else
    ToastError("Wrong File Type")
}

      useEffect(() => {

          if(JSON.parse(sessionStorage.getItem("TrendTypes"))!==null)
          SetTrendsTypes(JSON.parse(sessionStorage.getItem("TrendTypes")))
          AxiosGetRequest(Urls.Acounts.AvailAccounts,[{"HeadKey":"orderType","HeadValue":"tweet"}]).then(response => {
            if(response[0])
            {
              setAvailAccounts(response[1].data)
            }
            else
              ToastError("Tweet List didnt loaded")
          })

      }, [])

  const BulkAdd = () => {
    let Repeat = document.getElementById("bulkAddInput").value
    for (let index = 0; index < Repeat; index++) {
      AddTweet(TweetList,setTweetList)
    }
    setShowPopover(false)
  };

  const handleRightClick = (event) => {
    event.preventDefault();
    setShowPopover(!showPopover)
  };

  const handleKeyDown = (event) => {
  handleKeyPress(event, 'Enter', BulkAdd);
 };

    return (
        <>
        <div className=" mobile-send-button">
          <Button onClick={()=>SendTweets()}>Send </Button>
        </div>
        <Card>
            <ListGroup >
                <div className="d-flex flex-row justify-content-between">
                    <h4>Tweets List</h4>
                    <div className="d-flex" style={{gap:"0.5rem"}}>
                    <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"Apply media for All tweets")}>
                        <div onClick={(e)=>handleModal(-1,bulkSelectImage,true)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"#026e31"}}>
                            <i className="fa-regular fa-images text-white  Temp-Icon" ></i>
                        </div>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"Template Bulk List")}>
                        <div onChange={(e)=>ReadFile(e)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"rgb(78 78 78)"}}>
                            <a href="./TextTemplate.txt"  download>
                            <i className="fa-regular fa-file text-white  Temp-Icon" ></i>
                            </a>
                        </div>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,<p className="p-0 m-0">Add by List <br/> (You can use template list beside this button)</p>)}>
                      <div  className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"#026e31"}}>
                          <i  className="fa-solid fa-file-plus text-white TweetFileCont" >
                          <input onChange={(e)=>ReadFile(e)} id='input-file' className="TweetFile" type='file' />
                          </i>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" show={showPopover} delay={{ show: 500, hide: 100 }}overlay={
                      <Popover   placement="bottom" style={{borderColor:"#97e7ff",borderRadius:"1vh"}}>
                        <Popover.Content>
                          <input id="bulkAddInput" className="form-control input-default " type="number"  onKeyPress={handleKeyDown} />
                        </Popover.Content>
                        <Popover.Title closeButton onClick={()=>BulkAdd()} style={{background:"#97e7ff"}}>
                          Add
                        </Popover.Title>
                      </Popover>}>
                      <div onContextMenu={handleRightClick}   onClick={()=>AddTweet(TweetList,setTweetList)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"#026e31"}}>
                          <i className="fa-solid fa-plus text-white" ></i>
                      </div>
                    </OverlayTrigger>
                    </div>
                    
                </div>
                <TransitionGroup className={"TweetListGrid"}>
                {TweetList.map((Tweet,index) =>(
                    <CSSTransition
                    key={index}
                    nodeRef={Tweet.nodeRef}
                    timeout={500}
                    classNames="item">
                    <ListGroup.Item style={{background:"#b5e0f117",}} className={"rounded mb-2 mt-2 border border-primary Tweetitemgrid " + (focused==index ? "focusedBox":"")}   ref={Tweet.nodeRef}>
                      <TweetWithMedia   
                      TweetList={TweetList} 
                      setTweetList={setTweetList} 
                      Tweet={Tweet} 
                      Index={index} 
                      ChangeTweet={ChangeTweet} 
                      RemoveTweet={RemoveTweet} 
                      handleModal={handleModal}
                      focused={focused}
                      setfocused={setfocused}
                      />
                    </ListGroup.Item>
                   </CSSTransition> 
                    ))}
                </TransitionGroup>
            </ListGroup>
                <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}} >
                  <OperationColumn Options={Options} setOptions={setOptions}/>
                  <SubjectColumn Options={Options} setOptions={setOptions} setAvailAccounts={setAvailAccounts} setLoader={setLoader} type={"tweet"}/>
                  <TagListColumn Options={Options} setOptions={setOptions} />
                </Row>
                <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                <AccountColumn  Options={Options} setOptions={setOptions} CustomClass={"pt-0"} />
                <GenderColumn customID={1}   xl={"3"} Options={Options} setOptions={setOptions} MaleRef={MaleCheckRef} FemaleRef={FemaleCheckRef} />
                  <Col  xl={4}>
                  <div className="d-flex flex-row mt-3">
                    <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-2 mb-2'>
                        <input type='checkbox' className='custom-control-input ' id='Repeat' value={Options.isNeedtextRepetition} onChange={(e) => RepeatCheckBoxFunction('isNeedtextRepetition',e.target.checked,setOptions,Options)}/>
                        <label className='custom-control-label ' htmlFor='Repeat'>Repeat:</label>
                    </div>
                    <div style={{zIndex:0,marginTop:"-0.4rem"}}  className='d-flex custom-control custom-checkbox mr-3 pl-0'>
                      <input className='form-control input-default w-50 ' onChange={(e) => ChangeHandler('countRepetition',e.target.value,setOptions,Options)} value={Options.countRepetition} placeholder='Amount' type="number" disabled={!RepeatVal}/>
                      <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"If checked, tweets will be repeated with slighlty changed text")}>
                        <i className="d-flex justify-content-center align-items-center c-pointer ml-2 fa-regular fa-question-circle"></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                  </Col>
                </Row>
                <Row className="w-100 ml-0 pt-3 pb-3 mt-2 "  style={{borderTop:"1px solid #dbdbdb"}}>
                  <Col className="d-flex " xl={7} style={{flex:"0 0 45%",width:"45%"}}>
                  <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-2'>
                          <input
                          type='checkbox'
                          className='custom-control-input'
                          id='Trending'
                          onChange={(e) => TrendVals(e.target.checked)}
                          />
                          <label
                          className='custom-control-label '
                          htmlFor='Trending'
                          >
                          Use Trending Hashtags
                          </label>
                      </div>
                      <Select isDisabled={!TrendVal} ref={SelectRef}  defaultValue={selectedOption} placeholder="Position" onChange={(e)=>ChangeHandler('trendMethodAddType',e !=null ? e.value:null,setOptions,Options)} options={TrendTypes}/>
                      <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(1,"Default Value is Top")}>
                        <i className="d-flex justify-content-center align-items-center c-pointer ml-2 pb-3 fa-regular fa-exclamation-circle"></i>
                      </OverlayTrigger>
                      <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-2'>
                          <input
                          type='checkbox'
                          className='custom-control-input'
                          id='hashtaqg'
                          disabled={true}
                          />
                          <label
                          className='custom-control-label '
                          htmlFor='hashtaqg'
                          >
                            Use 1st hashtag
                          </label>
                      </div>
                  </Col>
                  <Col className="d-flex align-items-center" style={{flex:"0 0 20%",gap:"0.5rem"}}>
                    <HashtagMinMaxInput DisabledTrigger={TrendVal} Options={Options} setOptions={setOptions}></HashtagMinMaxInput>
                  </Col>
                  <Col className="d-flex align-items-center ml-4" style={{flex:"0 0 15%",gap:"0.5rem"}}>
                    <HashtagSourceSelect  DisabledTrigger={TrendVal} Options={Options} setOptions={setOptions}/>
                  </Col>
                </Row>
        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=>SendTweets()}>Send </Button>
          <Button variant='outline-primary '>Clear </Button>
          <h5 className="mt-3 mr-2"> Available Accounts : {AvailAccounts}</h5>
          {Loader ? <Spinner className="mt-3" animation="border" variant="primary" /> : "" }
       </Row>
       <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
          <Modal.Title>Add Media</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className="container ">
              <div {...getRootProps({className: 'dropzone dropzoneTweet mb-3 mt-2 d-flex c-pointer justify-content-center align-items-center '},)}>
                <input {...getInputProps()} />
                <p className="mb-0 pl-2 pr-2 pb-3 pt-3 text-center">Drag and drop media files here, or click to select files<br></br> <span className="fs-15 text-black">only Jpg and mp4</span></p>
              </div>
              <div class="custom-control custom-switch mb-2 " style={{placeSelf:"flex-start"}}>
                <input type="checkbox" class="custom-control-input CustomInputSwitch " onChange={(e) => setListType(e.target.checked)} id="customSwitch1"/>
                <label class="custom-control-label CustomLabelSwitch " for="customSwitch1">{ListType ? "Video" : "Image"}</label>
              </div>
              <aside className="w-100">
                <ImageGallery ListType={ListType} selectedImages={selectedImages} setselectedImages={setselectedImages}  Images={files} Vids={Vids} selectedTweet={selectedTweet}></ImageGallery>
              </aside>
            </section>
          </Modal.Body>
          <Modal.Footer>
          {Loader ? <Spinner animation="border" variant="primary" /> : "" }
          <Button onClick={() => handleClose()} variant="outline-primary">
            cancel
          </Button>
          <Button onClick={() =>AddMedia()} variant="primary" >
            Save Changes
          </Button>
          </Modal.Footer>
	      </Modal>
        </>
    )
}